import React from 'react'
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import { IArtist } from '../typings';
import './artist.scss';
import { ExternalLink } from '../components/navigation/externalLink';

interface IProps {
  data: { artist: IArtist };
}

const link = (url: string, site: string): JSX.Element => {
  if (!!url) {
    return <a href={url} target='_blank' rel='noreferrer noopener'>{site}</a>
  }
  return <></>;
}

const ArtistPage: React.FunctionComponent<IProps> = (props: IProps) => {

  const { artist } = props.data;
  return (
    <Layout className='page-split artist-page'>
      <div className="page-split-left">
        <div className="artist-image">
          <img src={artist.imageSmall} alt={artist.title} aria-hidden='true' />
        </div>
      </div>
      <div className="page-split-right">
        <h2>{artist.title}</h2>
        <p>{artist.rawMarkdownBody}</p>
        <ExternalLink url={artist.soundcloudlink} site='soundcloud' />
        <ExternalLink url={artist.spotifylink} site='spotify' />
        <ExternalLink url={artist.bandcamplink} site='bandcamp' />
        <ExternalLink url={artist.mixcloudlink} site='mixcloud' />
        <ExternalLink url={artist.facebooklink} site='facebook' />
        <ExternalLink url={artist.bandcamplink} site='bandcamp' />
      </div>
    </Layout>
  )
}

export default ArtistPage;

export const pageQuery = graphql`
  query($path: String!) 
  {
    artist: markdownRemark(fields: { path: { eq: $path } }) 
    {
      html
      title
      imageSmall
      soundcloudlink
      facebooklink
      instagramlink
      rawMarkdownBody
    }
  }
`